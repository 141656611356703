import {defineStore} from 'pinia';
import {ref, computed} from 'vue';
import {getFromAny} from "../composables/getFromAny.js";
import {useBreadcrumbStore} from "./BreadcrumbStore.js";
// const { $emitter } = useNuxtApp();


export const useCatalogStore = defineStore('catalog', () => {
    const catalogResource = ref({});

    const products = computed(() => catalogResource.value.products);
    const catalog = computed(() => catalogResource.value);
    const paginator = computed(() => catalogResource.value.paginator);

    // const products = ref([]);
    // const paginator = ref();
    // const options = ref({
    //     sorting: 'name_asc',
    //     view: 'pics',
    //     limit: 30
    // });
    const isLoading = ref(false);
    const sortingOptions = ref({
        'name_asc': 'catalog.name_asc',
        'name_desc': 'catalog.name_desc',
        'fine_weight_asc': 'catalog.fine_weight_asc',
        'fine_weight_desc': 'catalog.fine_weight_desc',
        'price_asc': 'catalog.price_asc',
        'price_desc': 'catalog.price_desc',
        'is_popular_desc': 'catalog.is_popular_desc',
        'best_price_per_oz_asc': 'catalog.best_price_per_oz_asc',
        'introduced_since_asc': 'catalog.introduced_since_asc',
        'introduced_since_desc': 'catalog.introduced_since_desc',
    });
    const sortingLabel = computed(() => sortingOptions.value[getOption('sorting')]);


     async function fetchCatalog(term) {
        isLoading.value = true;
         const queryParams = useRoute().query;
         const queryString = new URLSearchParams(queryParams).toString();
         const url = `catalog?${queryString}`;

         const body = {
             ...catalog.value.filters_selected,
             ...catalogResource.value.options,
             url: window ? window.location.pathname : null,
             page: paginator.value?.current_page,
             term,
         }

         return await getFromAny(url, {
             method: 'POST',
             body
         }).then(data => {
            isLoading.value = false;
            catalogResource.value = data.catalog;

            //update URL

            updateUrl();

            if (process.client && data.meta) {
                const { title, description, hreflang, og } = data.meta;
                const headOptions = {
                    title,
                    description,
                    link: [
                        {
                            rel: 'canonical',
                            href: data.catalog.url,
                        },
                        ...hreflang,
                    ],
                    meta: [
                        {
                            property: 'og:url',
                            content: data.catalog.url,
                        },
                        ...og
                    ],
                };
                useHead(headOptions);
            }

            if (data.breadcrumbs) {
                useBreadcrumbStore().items = data.breadcrumbs;
            }

            return data;
        });
    }

    function updateUrl() {
        if (!catalogResource.value) {
            return;
        }
        let url = catalogResource.value.url;

        // Add page param
        if (paginator.value.current_page > 1) {
            const operator = url.includes('?') ? '&' : '?';
            url += operator + 'page=' + encodeURIComponent(paginator.value.current_page);
        }

        if (process.client) {
            window.document.title = catalogResource.value.teaser_title;
            window.history.replaceState(window.history.state, null, url);
        }
    }

    function setSorting(sorting) {

        setOption('sorting', sorting);
        paginator.value.current_page = 1;

        fetchCatalog();
        // this.hideMobileFilters();
    }

    function setView(view) {
        setOption('view', view);
    }

    function storeOptions(opts) {
        useClientFetch('catalog/options', {
            method: 'POST',
            body: opts
        });
    }

    function setOption(key, value) {
        catalogResource.value.options[key] = value;

        storeOptions({[key]:value});
    }

    function getOption(key) {
         if (!catalogResource.value?.options) {
             return;
         }

        return key in catalogResource.value.options ? catalogResource.value.options[key] : null;
    }

    return {
        catalogResource,
        fetchCatalog,
        setSorting,
        setView,
        getOption,
        sortingOptions,
        sortingLabel,
        catalog,
        products,
        paginator,
        isLoading
    };
});
